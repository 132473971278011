import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    navbar: {
      home: 'Home',
      yan: 'Yan',
      blog: 'Blog',
      toys: 'Toys',
      notebook: 'Notebook',
      findme: 'Find Me'
    }
  },
  zh: {
    navbar: {
      home: '主页',
      yan: '每日一言',
      blog: '博客',
      toys: '小玩具',
      notebook: '公开笔记本',
      findme: '找到我'
    }
  }
};

const i18n = createI18n({
  locale: 'zh', // 设置默认语言
  messages,
});

export default i18n;
