import { DirectiveBinding } from 'vue';
import VanillaTilt from 'vanilla-tilt';

const tiltDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    VanillaTilt.init(el, binding.value || {});
  },
  unmounted(el: HTMLElement) {
    if (el.vanillaTilt) {
      el.vanillaTilt.destroy();
    }
  }
};

export default tiltDirective;
