import { createStore } from 'vuex';

const THEME_KEY = 'theme';

export default createStore({
  state: {
    theme: localStorage.getItem(THEME_KEY) || 'light',
  },
  getters: {
    currentTheme(state) {
      return state.theme;
    },
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem(THEME_KEY, theme);
    },
  },
  actions: {
    toggleTheme({ commit, state }) {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      commit('setTheme', newTheme);
    },
    initializeTheme({ commit }) {
      const savedTheme = localStorage.getItem(THEME_KEY);
      if (savedTheme) {
        commit('setTheme', savedTheme);
      } else {
        const hour = new Date().getHours();
        const theme = hour >= 6 && hour < 18 ? 'light' : 'dark';
        commit('setTheme', theme);
      }
    },
  },
  modules: {},
});


