import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import i18n from './i18n';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// 添加 GitHub 图标到库中
library.add(faGithub);

const app = createApp(App);

// 注册 FontAwesomeIcon 组件
app.component('font-awesome-icon', FontAwesomeIcon);

app.use(store).use(router);
app.use(i18n);

store.dispatch('initializeTheme'); // 初始化主题

app.mount('#app');
