<template>
  <div class="container">
    <div class="one">
      <div class = "me">
        <div class="avatar">
          <img src="/avatar.jpeg" alt="Avatar" class="avatar-img">
        </div>
        
        <div class="name">
          Tony Blue
        </div>
        <div class="quote"> 
          一个不想再Blue的Tony，一个只想快乐的疯子
        </div>
      </div>

      <div class="everyday">
        <div class = "tutle">
          每日一言
        </div>
      </div>
    </div>
    
    <div class = "two">
      <div class="navigation-buttons">
        <div class="s_button">&lt;</div>
        <div class="content">正在读</div> 
        <div class="s_button">&gt;</div>
      </div> 
      
      <div class="carddd">
        <CardComponent/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import tiltDirective from '@/directives/tilt'; // 修改路径以匹配实际文件结构
import CardComponent from '../components/ThreeDCard.vue'

export default defineComponent({
  name: 'HomeView',
  directives: {
    tilt: tiltDirective
  },
  components:{
    CardComponent
  }
});
</script>

<style scoped>
/* 样式与之前相同 */
body, html {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}



/* 整体布局 */
.container {
  margin-top: 40px;
  padding: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  position: relative; /* 设置容器为相对定位 */
  border: black solid 1px;
}

/* 第一部分，上面包含，一个小的主页和每日一言 */
.one {
  display: flex;
  justify-content: space-between;
  width: 100%; /* 让子元素在容器内左右分开 */
  align-items: center; /* 垂直居中对齐 */
}

/* me 就是我的主页 */
.me {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 210px;
  text-align: center;
  border: black solid 1px;
  background-color: white;
}

.avatar {
  margin-bottom: 20px; /* Optional: adjust as needed */
}
.avatar-img {
  width: 100px; /* Adjust size as needed */
  height: 100px;
  border-radius: 50%;
}
.name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px; /* Optional: adjust as needed */
}
.quote {
  font-size: 16px;
  color: #555;
}



/* 每日一言 */
.everyday{
  align-items: left;
  height:  210px;
  width:  950px;
  border: black solid 1px;
  margin-left: 30px;
}



/* 第二部分 */
.two{
  display: flex;
  justify-content: space-between;
  width: 100%; /* 让子元素在容器内左右分开 */
  align-items: center; /* 垂直居中对齐 */
}


/* 导航的按钮 */
.navigation-buttons {
  position: relative;
  top: 0px; /* 调整按钮组的垂直位置 */
  left: 20px; /* 调整按钮组的水平位置 */
  display: flex;
  justify-content: space-between; /* 让按钮间距离相等并排列 */
  width: 210px; /* 调整按钮组的宽度 */
  border: black  solid 1px;
  align-items: center;
}
.content{

}


.s_button {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: gray;
  background-color: white;
  font-size: 25px;
  border-radius: 50%;
}

.carddd {
  width: 868px;
  height: 500px;
  margin-top: 20px;
  border: black solid 1px;
}
</style>

