<template>
    <nav class="navbar">
      <div class="navbar-container">
        <div class="navbar-brand">
          <router-link class="navbar-item" to="/" exact-active-class="active">{{ $t('navbar.home') }}</router-link>
          <router-link class="navbar-item" to="/yan" exact-active-class="active">{{ $t('navbar.yan') }}</router-link>
        </div>
        <div class="navbar-menu">
          <div class="navbar-start">
            <router-link class="navbar-item" to="/" exact-active-class="active">{{ $t('navbar.blog') }}</router-link>
            <router-link class="navbar-item" to="/about" exact-active-class="active">{{ $t('navbar.toys') }}</router-link>
            <router-link class="navbar-item" to="/contact" exact-active-class="active">{{ $t('navbar.notebook') }}</router-link>
            <router-link class="navbar-item" to="/contact" exact-active-class="active">{{ $t('navbar.findme') }}</router-link>
            <a class="navbar-item" href="https://github.com/yourusername" target="_blank">
              <i class="iconfont icon-message navbar-icon"></i>
            </a>
            <a class="navbar-item" href="https://github.com/yourusername" target="_blank">
              <i class="iconfont icon-github navbar-icon"></i>
            </a>
            <a class="navbar-item" href="#" @click.prevent="toggleTheme">
              <i :class="['navbar-icon', themeIconClass]"></i>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </template>
  
  <script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  
  export default defineComponent({
    name: 'NavBar',
    setup() {
      const store = useStore();
      const { t, locale } = useI18n();
  
      const toggleTheme = () => {
        store.dispatch('toggleTheme');
      };
  
      const themeIconClass = computed(() => {
        return store.state.theme === 'light' ? 'iconfont icon-moon' : 'iconfont icon-sun';
      });
  
      const changeLanguage = (lang: string) => {
        locale.value = lang;
      };
  
      return {
        t,
        toggleTheme,
        themeIconClass,
        changeLanguage,
      };
    }
  });
  </script>
  
  <style scoped>
  .navbar {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: inherit;
    padding: 2.2rem 0; /* 增加上下内边距,使导航栏高度增加 */
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1470px; /* 减小最大宽度,使导航栏内容更集中 */
    padding: 0 1rem; /* 增加左右内边距,使导航栏内容与边缘有更多空隙 */
  }
  
  .navbar-brand {
    display: flex;
  }
  
  .navbar-menu {
    flex-grow: 1;
  }
  
  .navbar-start {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  }
  
  .navbar-item {
    color: inherit;
    text-decoration: none;
    margin: 0 0.6rem;
    font-size: 18px;
  }
  
  .navbar-icon {
    font-size: 1.2rem; /* 增大图标字体大小,可根据需要调整 */
  }
  
  .navbar-item:hover {
    color: #394150;
    font-weight: bold; /* 设置字体加粗 */
  }
  
  .active {
    font-weight: bold;
    color: #394150; /* 选中的项目颜色 */
  }
  
  .navbar-end {
    display: flex;
    gap: 1rem;
  }
  </style>
  
  
  
  
  
  
  
  
  