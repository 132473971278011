<template>
  <div :class="currentTheme" class="app-container">
    <NavBar />
    <router-view />
    <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2024075263号</a> Me
  </div>

</template>

<script lang="ts">
import { defineComponent, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import NavBar from './components/NavBar.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
  },
  setup() {
    const store = useStore();

    const currentTheme = computed(() => store.state.theme);

    // Add or remove the class to the body based on the content height
   



    return {
      currentTheme,
    };
  },
});
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
} 

/* body.no-scroll {
  overflow: hidden;
} */

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Light theme styles */
.light {
  background-color: white;
  color: #999da5;
}

/* Dark theme styles */
.dark {
  background-color: black;
  color: #898a8d;
}
</style>







