<template>
    <div class = "card-container">
    <div class="card" ref="card" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave">
      <div class="card-shine" ref="cardShine"></div>
      <div class="card-shadow"></div>
      <div class="card-layer">
        <div class="title">写出我心</div>
        <div class="jump">点击跳转<i class="iconfont icon-tiaozhuan-zhuanqu navbar-icon"></i></div>
        
        <div class="line"></div>
        <div class="sub-title"> 普通人如何表达自己 </div>
        <div class="writing-progress"><i class="iconfont icon-icon_writing navbar-icon"> </i> 读书笔记进度30%</div>
        <div class="reading-progress"><i class="iconfont icon-tongji navbar-icon"> </i>阅读进度40%</div>
        <div class="introduction">
          在书中，作者将写作和修行结合，分享在写作中找到的驯服自己和释放心灵的方法。如何开始写作？如何寻找题材？如何应对逃避和拖延？如何突破瓶颈？……指导读者通过写作全面探讨自己的生命，重新注视生命的细节。
          <br /><br />
          作者认为，任何人都可以通过写作了解自己，用笔表达喜悦和疼痛，如童年时的愉快暑假，面对父亲生命垂危时的痛不欲生、离婚的煎熬……在勾勒生活事件的过程中，领会生命的奥义，这本书谈论的不只是写作，更是生活哲学，生命智慧。通过写作给生活赋予光芒、色彩和故事，使我们再次审视这趟凡尘之旅，为平凡的生活心醉神迷。
        </div>
        <div class="sentence">我写字的手<br /><br />可以打倒拳王阿里</div>
        <div class="author">—Natalie Goldberg</div>
        <div class="lianjie">521feifei/reading/writingbones</div>
      </div>
    </div>
</div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  
  export default defineComponent({
    name: 'CardComponent',
    setup() {
      const card = ref<HTMLElement | null>(null);
      const cardShine = ref<HTMLElement | null>(null);
      const speed = 10;
      let calcX = 0;
      let calcY = 0;
  
      const handleMouseMove = (e: MouseEvent) => {
        if (!card.value || !cardShine.value) return;
  
        const box = card.value.getBoundingClientRect();
        calcX = (e.clientY - box.y - box.height / 2) / speed;
        calcY = (e.clientX - box.x - box.width / 2) / speed * -1;
        card.value.style.transition = 'transform 0.10s ease';
        card.value.style.transform = `rotateX(${calcX}deg) rotateY(${calcY}deg)`;
        const calc1 = e.clientX - box.x;
        const calc2 = e.clientY - box.y;
        cardShine.value.style.background = `radial-gradient(circle at ${calc1}px ${calc2}px, rgba(255, 255, 255, 0.9), rgba(225, 225, 225, 0.1))`;
      };
  
      const handleMouseLeave = () => {
        if (!card.value || !cardShine.value) return;
  
        window.requestAnimationFrame(() => {
          setTimeout(() => {
            card.value!.style.transition = 'transform 0.5s ease';
            cardShine.value!.style.transition = 'background 0.5s ease';
            setTimeout(() => {
              cardShine.value!.style.background = 'none';
              card.value!.style.transform = `rotateX(${-calcX * 0.2}deg) rotateY(${-calcY * 0.2}deg)`;
              setTimeout(() => {
                card.value!.style.transform = `rotateX(0deg) rotateY(0deg)`;
              }, 500);
            }, 100);
          }, 300);
          card.value!.style.transition = 'transform 0 ease';
        });
      };
  
      onMounted(() => {
        card.value = document.querySelector('.card');
        cardShine.value = document.querySelector('.card-shine');
      });
  
      return {
        card,
        cardShine,
        handleMouseMove,
        handleMouseLeave,
      };
    },
  });
  </script>
  
  <style scoped>
  * {
    padding: 0;
    margin: 0;
  }
  
  
  .card-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 439px;
  }
  
  div {
    transform-style: preserve-3d;
    cursor: pointer;
    transition: all 0.1s;
  }
  
  .card {
    width: 300px;
    height: 420px;
    margin: 0 auto;
    position: relative;
    top: 40px;
  }
  
 .card-shine{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    /* border: 0.1px solid rgb(255, 255, 255); */
    border-radius: 10px;
    /* background: linear-gradient(270deg, rgba(223,223,223,0), rgba(0,0,0,0.5)); */
    z-index: 8;
}

.card-shadow{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    z-index: 0;
}

.card-layer{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(240, 240, 240);
    border-radius:10px;
    overflow: hidden;
    color: black;
}
  
  .title {
    position: absolute;
    top: 30px;
    left: 20px;
    font-size: 48px;
  }
  
  .jump {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: auto;
    top: 5px;
    left: 70%;
    font-size: 12px;
  }
  .jump i {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
  }
  
  .line {
    position: absolute;
    top: 115px;
    left: 65px;
    width: 100px;
    height: 2px;
    background-color: black;
  }
  
  .sub-title {
    position: absolute;
    top: 125px;
    left: 90px;
    font-size: 20px;
  }
  
  .writing-progress {
    position: absolute;
    top: 160px;
    left: 25px;
    font-size: 12px;
  }
  
  .reading-progress {
    position: absolute;
    top: 160px;
    left: 170px;
    font-size: 12px;
  }
  
  .introduction {
    position: absolute;
    top: 190px;
    left: 20px;
    width: 260px;
    height: 200px;
    font-size: 8px;
  }
  
  .sentence {
    position: absolute;
    top: 320px;
    left: 20px;
    font-size: 16px;
    line-height: 10px;
  }
  
  .author {
    position: absolute;
    top: 337px;
    left: 160px;
    font-size: 16px;
  }
  
  .lianjie {
    position: absolute;
    top: 370px;
    left: 20px;
    font-size: 22px;
  }
  </style>
  